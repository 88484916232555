














import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Platform extends mixins(MenuMixin) {
  menu = [
    {
      title: '平台管理',
      icon: 'setting',
      children: [
        { title: '系统授权管理', key: '/manage/permision', icon: 'idcard' },
        { title: '操作日志管理', key: '/manage/syslog', icon: 'container' }
      ]
    }
  ];

  created() {}
}
